import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "../../../components/__common/Typography/Typography";
import "./Consignment.scss";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES_CONSIGNMENT_PAGE } from "../../../configs/layout";
import { getConsignmentDate } from "../../../utils/UtilsConverter";
import { ROUTES } from "../../../configs/routes";

const BdpConsignment = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    const main = document.getElementById("main-body");
    if (
      pathname === ROUTES.OLD_CONSIGNMENT ||
      pathname === ROUTES.OLD_CONSIGNMENT_WITH_KR
    ) {
      history.replace(ROUTES.BDP_CONSIGNMENT);
    }
    if (!main) return;
    if (ROUTES_CONSIGNMENT_PAGE.includes(pathname)) {
      main.style.height = "100vh";
      main.style.marginTop = "0";
    } else {
      main.style.overflow = "auto";
    }
  }, [pathname]);

  return (
    <div className="consignment" data-testid="bdp-consignment">
      <div className="table-container">
        <table cellSpacing="0">
          <tbody>
            <tr>
              <td colSpan={2}>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_COMPANY_KR")}
                </Typography>
              </td>
              <td colSpan={2}>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_COMPANY")}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_SAMSUNG_KR")}
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_SAMSUNG_OPERATIONS_KR")}
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_SAMSUNG")}
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  {t("PRIVACY_BDP_CONSIGNMENT_SAMSUNG_OPERATIONS")}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2" weight="w400">
                  <Trans
                    i18nKey="PRIVACY_BDP_CONSIGNMENT_GOOGLE_KR"
                    components={{
                      a: (
                        // eslint-disable-next-line
                        <a
                          href="https://cloud.google.com/terms/subprocessors"
                          target="_blank"
                          rel="noreferrer"
                        />
                      ),
                    }}
                  />
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  <Trans
                    i18nKey="PRIVACY_BDP_CONSIGNMENT_GOOGLE_OPERATIONS_KR"
                    components={{
                      a: (
                        // eslint-disable-next-line
                        <a
                          href="https://cloud.google.com/terms/subprocessors"
                          target="_blank"
                          rel="noreferrer"
                        />
                      ),
                    }}
                  />
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  <Trans
                    i18nKey="PRIVACY_BDP_CONSIGNMENT_GOOGLE"
                    components={{
                      a: (
                        // eslint-disable-next-line
                        <a
                          href="https://cloud.google.com/terms/subprocessors"
                          target="_blank"
                          rel="noreferrer"
                        />
                      ),
                    }}
                  />
                </Typography>
              </td>
              <td>
                <Typography variant="body2" weight="w400">
                  <Trans
                    i18nKey="PRIVACY_BDP_CONSIGNMENT_GOOGLE_OPERATIONS"
                    components={{
                      a: (
                        // eslint-disable-next-line
                        <a
                          href="https://cloud.google.com/terms/subprocessors"
                          target="_blank"
                          rel="noreferrer"
                        />
                      ),
                    }}
                  />
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <Typography variant="body2" className="consignment__date" weight="w500">
        <Trans
          i18nKey="PRIVACY_BDP_CONSIGNMENT_EFFECTIVE_DATE"
          values={{
            "%s": getConsignmentDate("2024년 4월 1일", "April 1, 2024"),
          }}
        />
      </Typography>
    </div>
  );
};

export default BdpConsignment;
