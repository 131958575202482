import { useDispatch, useSelector } from "react-redux";

import { API_BASE_URL, API_ENDPOINTS } from "../constants/api";
import Http from "../services/http";
import {
  actionSetCancelRequestPending,
  actionSetGlobalSiteLinkPopupClose,
  actionSetHasMoreHistory,
  actionSetHistoryMoreLoading,
  actionSetHistoryReqBody,
  actionSetIsUserEmailNotSet,
  actionSetMyDataLoadMoreHistory,
  actionSetRequestDetails,
} from "../store/actions/mydata-actions";
import { actionSetRequestDetailsLoading } from "../store/actions/mydata-actions";
import { actionSetMyDataHistory } from "../store/actions/mydata-actions";
import { actionSetMyDataHistoryLoading } from "../store/actions/mydata-actions";
import { actionSetCancelRequestComplete } from "../store/actions/mydata-actions";
import { actionSetFilePasswordSend } from "../store/actions/mydata-actions";
import {
  actionSetSummary,
  actionSetSummaryLoading,
  actionSetRequestLoading,
  confirmRequestData,
} from "../store/actions/mydata-actions";
import { BaseEntityRes } from "../types/base";
import {
  HISTORY_REQUEST_TYPE,
  MyDataHistoryEntity,
  MyDataHistoryReqBody,
  SORT_TYPE,
} from "../types/history";
import {
  SummaryEntityRes,
  RequestEntityRes,
  RequestEntityReq,
} from "../types/mydata";
import { REQUEST_TYPE } from "../types/user";
import { UtilsDateTime } from "../utils/UtilsDateTIme";
import { RequestDetailsEntityRes } from "../types/request-details";
import {
  myDataHistoryDefaults,
  myDataHistoryReqBodyDefaults,
} from "../data/defaults";
import { UtilsRequest } from "../utils/UtilsRequest";
import { RCODES } from "../constants/rcodes";
import { ROUTES } from "../configs/routes";
import triggerGAEvent from "../utils/UtilsTriggerGAEvent";
import { UtilsLocale } from "../utils/UtilsLocale";

import {
  EVENT_CATEGORY_MY_DATA,
  EVENT_CATEGORY_MY_DATA_HISTORY,
  EVENT_MY_DATA_ACTION_REQUEST_CONFIRMATION,
  EVENT_MY_DATA_HISTORY_ACTION_ALL,
  EVENT_MY_DATA_HISTORY_ACTION_DELETION,
  EVENT_MY_DATA_HISTORY_ACTION_DOWNLOAD,
  EVENT_MY_DATA_HISTORY_ACTION_OPTOUT,
  EVENT_ACTION_DETAILS_CANCEL_REQUEST,
  EVENT_ACTION_DETAILS_SEND_FILE_PASSWORD,
  EVENT_CATEGORY_REQUEST_DETAILS,
} from "../constants/analytics";
import { ApplicationState } from "../store";
import { LayoutState } from "../store/reducers/layout-reducer";
import Cookies from "js-cookie";
import { LANGUAGE_TRANS, USER_DATA_NAMESPACE } from "../constants/storage";
import { LocalStorage } from "../services/storage";
import { UserState } from "../store/reducers/user-reducer";
import { UtilsHome } from "../utils/UtilsHome";
import { actionSetShowServiceToastError } from "../store/actions/mydata-actions";

export const useMyDataHooks = () => {
  const dispatch = useDispatch();

  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  // state

  const loadSummary = async () => {
    try {
      dispatch(actionSetSummaryLoading());
      // const lsUserData = JSON.parse(
      //   LocalStorage.get(USER_DATA_NAMESPACE) || ""
      // );

      const url = `${API_BASE_URL}${API_ENDPOINTS.SUMMARY}`;
      const res: SummaryEntityRes = await Http.get(url, {
        lang: layoutState.languageTrans,
        sa_cc: UtilsLocale.convertISO3toISO2(userState.userData.countryCode),
        site_cc: layoutState.countryCode,
      });

      dispatch(actionSetSummary(res));
    } catch (e: any) {
      console.error(e);
    }
  };

  const confirmRequest = async (
    services: Array<string>,
    requestType: REQUEST_TYPE,
    confirmToken: string
  ) => {
    try {
      dispatch(actionSetRequestLoading());
      const tz = UtilsDateTime.getLocalTimezone();
      const arrServices: { service_code: string }[] = [];
      let url;
      let language = Cookies.get(LANGUAGE_TRANS) || layoutState.languageTrans;
      const lsUserData = JSON.parse(
        LocalStorage.get(USER_DATA_NAMESPACE) || ""
      );

      services.forEach((service) => {
        arrServices.push({ service_code: service });
      });

      if (
        lsUserData?.countryCode === "USA" &&
        layoutState.countryCode === "US" &&
        language === "en"
      ) {
        language = "en-rUS";
      }

      if (requestType === REQUEST_TYPE.DOWNLOAD) {
        url = `${API_BASE_URL}${API_ENDPOINTS.MYDATA_DOWNLOAD}`;
      } else if (requestType === REQUEST_TYPE.DELETE) {
        url = `${API_BASE_URL}${API_ENDPOINTS.MYDATA_DELETE}`;
      } else {
        url = `${API_BASE_URL}${API_ENDPOINTS.MYDATA_UNSUBSCRIBE}`;
      }

      const data: RequestEntityReq = {
        user_request_date: UtilsDateTime.dateObjectToStringUTC(new Date()),
        request_subtype: "R",
        language,
        country: layoutState.countryCode,
        sa_country: UtilsLocale.convertISO3toISO2(lsUserData?.countryCode),
        service_count: arrServices.length,
        service_list: arrServices,
        tz,
        geoip_country: sessionStorage.getItem("GEO_IP"),
      };
      const extraHeaders = {
        "x-sc-confirm-token": confirmToken,
      };
      let res: RequestEntityRes;
      if (requestType === REQUEST_TYPE.UNSUBSCRIBE) {
        res = await Http.post(url, data, extraHeaders);
      } else {
        res = await Http.post(url, data);
      }

      if (
        res.rcode === RCODES.SUCCESS ||
        res.rcode === RCODES.GK_DUPLICATE_REQUEST
      ) {
        triggerGAEvent(
          EVENT_CATEGORY_MY_DATA,
          EVENT_MY_DATA_ACTION_REQUEST_CONFIRMATION[requestType]
        );

        // allow normal flow with gk duplicate request
        dispatch(confirmRequestData(res));
        resetServiceRequestErrorToast();
      }

      UtilsRequest.removeRequestInfo();
    } catch (e: any) {
      switch (e.rcode) {
        case RCODES.SA_UNVERIFIED_ERROR:
          dispatch(
            actionSetShowServiceToastError({
              isError: true,
              hasRendered: true,
              isSAUnverifiedError: true,
            })
          );
          break;
        default:
          dispatch(
            actionSetShowServiceToastError({
              isError: true,
              hasRendered: true,
              isSAUnverifiedError: false,
            })
          );
          break;
      }

      console.log(e);
    }
  };

  const clearConfirmRequestData = () => {
    dispatch(confirmRequestData({ request_number: "" }));
  };

  const loadRequestDetails = async (request_number: string) => {
    try {
      dispatch(actionSetRequestDetailsLoading());
      const lsUserData = JSON.parse(
        LocalStorage.get(USER_DATA_NAMESPACE) || ""
      );

      const url = `${API_BASE_URL}${API_ENDPOINTS.DETAILS}`;
      const res: RequestDetailsEntityRes = await Http.post(
        url,
        {},
        {
          request_number,
          lang: layoutState.languageTrans,
          sa_cc: UtilsLocale.convertISO3toISO2(lsUserData?.countryCode),
          site_cc: layoutState.countryCode,
        }
      );
      dispatch(actionSetRequestDetails(res));
      return res;
    } catch (e: any) {
      console.error(e);
      const lsUserData = JSON.parse(
        LocalStorage.get(USER_DATA_NAMESPACE) || ""
      );

      const url = `${API_BASE_URL}${API_ENDPOINTS.HISTORY}`;
      const res: MyDataHistoryEntity = await Http.post(url, {
        request_type: HISTORY_REQUEST_TYPE.ALL,
        sort_by: SORT_TYPE.date_asc,
        limit: 10,
        offset: 0,
        lang: layoutState.languageTrans,
        sa_cc: UtilsLocale.convertISO3toISO2(lsUserData?.countryCode),
        site_cc: layoutState.countryCode,
      });

      // temporary fix, should implement api error handler
      if (e.rcode && e.rcode === RCODES.INVALID_REQUEST_NUMBER) {
        const currentURL = window.location.pathname;
        if (res.request_count > 0) {
          if (UtilsHome.hasLocalSite(userState.userCountryCode)) {
            window.location.href = ROUTES.MYDATA_HISTORY;
          } else {
            currentURL.includes(ROUTES.MYDATA_HISTORY)
              ? (window.location.href = ROUTES.MYDATA_HISTORY)
              : (window.location.href = ROUTES.MYDATA);
          }
        } else {
          window.location.href = ROUTES.ROOT;
        }
      }

      return;
    }
  };

  const sendFilePassword = async (request_number: string) => {
    try {
      triggerGAEvent(
        EVENT_CATEGORY_REQUEST_DETAILS,
        EVENT_ACTION_DETAILS_SEND_FILE_PASSWORD
      );
      const url = `${API_BASE_URL}${API_ENDPOINTS.SEND_FILE_PASSWORD}`;
      const res: BaseEntityRes = await Http.post(
        url,
        {},
        {
          request_number,
        }
      );

      if (res.rcode === 0) {
        dispatch(actionSetFilePasswordSend(true));
      }
    } catch (e: any) {
      console.error(e);
      switch (e.rcode) {
        case RCODES.FILE_PASSWORD_NOT_SENT:
          dispatch(actionSetFilePasswordSend(false));
          break;
        case RCODES.USER_EMAIL_NOT_SET:
          dispatch(actionSetIsUserEmailNotSet(true));
          break;
        default:
          dispatch(actionSetFilePasswordSend(false));
      }
    }
  };

  const closeFilePasswordModal = () => {
    dispatch(actionSetFilePasswordSend(false));
    dispatch(actionSetIsUserEmailNotSet(false));
  };

  const cancelRequest = async (request_number: string) => {
    triggerGAEvent(
      EVENT_CATEGORY_REQUEST_DETAILS,
      EVENT_ACTION_DETAILS_CANCEL_REQUEST
    );

    try {
      dispatch(actionSetCancelRequestPending(true));
      const url = `${API_BASE_URL}${API_ENDPOINTS.CANCEL_REQUEST}`;
      const res: BaseEntityRes = await Http.post(
        url,
        {},
        {
          request_number,
        }
      );

      dispatch(actionSetCancelRequestPending(false));

      if (res.rcode === 0) {
        dispatch(actionSetCancelRequestComplete(true));
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const closCancelRequestModal = () => {
    dispatch(actionSetCancelRequestComplete(false));
  };

  const handleGAEvent = (request_type: string, request_status?: string) => {
    const requestStatus = request_status ? request_status : "L";
    switch (request_type) {
      case HISTORY_REQUEST_TYPE.DOWNLOAD:
        triggerGAEvent(
          EVENT_CATEGORY_MY_DATA_HISTORY,
          EVENT_MY_DATA_HISTORY_ACTION_DOWNLOAD[requestStatus]
        );
        break;
      case HISTORY_REQUEST_TYPE.DELETE:
        triggerGAEvent(
          EVENT_CATEGORY_MY_DATA_HISTORY,
          EVENT_MY_DATA_HISTORY_ACTION_DELETION[requestStatus]
        );
        break;
      case HISTORY_REQUEST_TYPE.UNSUBSCRIBE:
        triggerGAEvent(
          EVENT_CATEGORY_MY_DATA_HISTORY,
          EVENT_MY_DATA_HISTORY_ACTION_OPTOUT[requestStatus]
        );
        break;
      case HISTORY_REQUEST_TYPE.ALL:
        triggerGAEvent(
          EVENT_CATEGORY_MY_DATA_HISTORY,
          EVENT_MY_DATA_HISTORY_ACTION_ALL[requestStatus]
        );
        break;
    }
  };

  const loadMyDataHistory = async (
    body: MyDataHistoryReqBody,
    isNextPage: boolean = false
  ) => {
    try {
      dispatch(actionSetHasMoreHistory(false));
      dispatch(actionSetMyDataHistoryLoading());
      dispatch(actionSetHistoryMoreLoading(true));

      handleGAEvent(body.request_type, body.request_status);
      if (!body.request_status) {
        delete body.request_status;
      }

      const lsUserData = JSON.parse(
        LocalStorage.get(USER_DATA_NAMESPACE) || ""
      );
      const saCountryCode =
        userState.userData.countryCode || lsUserData?.countryCode;
      if (!isNextPage) {
        dispatch(actionSetMyDataHistory(myDataHistoryDefaults));
        body = {
          ...body,
          offset: 0,
          lang: layoutState.languageTrans,
          sa_cc: UtilsLocale.convertISO3toISO2(saCountryCode),
          site_cc: layoutState.countryCode,
        };
      }

      dispatch(actionSetHistoryReqBody(body));
      const url = `${API_BASE_URL}${API_ENDPOINTS.HISTORY}`;
      const res: MyDataHistoryEntity = await Http.post(url, body);

      if (isNextPage) {
        dispatch(actionSetMyDataLoadMoreHistory(res));
      } else {
        dispatch(actionSetMyDataHistory(res));
      }

      dispatch(actionSetHistoryMoreLoading(false));

      if (res.next_offset && res.next_offset > 0) {
        dispatch(actionSetHasMoreHistory(true));
      } else {
        dispatch(actionSetHasMoreHistory(false));
      }

      return res;
    } catch (e: any) {
      console.error(e);
    }
  };

  const closeGlobalSiteLinkPopup = () => {
    dispatch(actionSetGlobalSiteLinkPopupClose());
  };

  const resetRequestBody = () => {
    dispatch(actionSetHistoryReqBody(myDataHistoryReqBodyDefaults));
  };

  const resetServiceRequestErrorToast = () => {
    dispatch(
      actionSetShowServiceToastError({
        isError: false,
        hasRendered: false,
        isSAUnverifiedError: false,
      })
    );
  };
  return {
    loadSummary,
    loadRequestDetails,
    loadMyDataHistory,
    sendFilePassword,
    closeFilePasswordModal,
    cancelRequest,
    closCancelRequestModal,
    confirmRequest,
    clearConfirmRequestData,
    closeGlobalSiteLinkPopup,
    resetRequestBody,
    resetServiceRequestErrorToast,
  };
};
