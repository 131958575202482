import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "nuka-carousel";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import "./UsageGuide.scss";
import Modal from "../../../../components/__common/Modal/Modal";
import { Typography } from "../../../../components/__common/Typography/Typography";
import { Button } from "../../../../components/__common/_controls";
import { DownloadGuide } from "./DownloadGuide";
import { DeleteGuide } from "./DeleteGuide";
import { OptOutGuide } from "./OptOutGuide";
import { useWindowSize } from "../../../../hooks/window-hooks";
import { ApplicationState } from "../../../../store";
import { LayoutState } from "../../../../store/reducers/layout-reducer";
import { ROUTES } from "../../../../configs/routes";
import { UtilsKeyboardAccessibility } from "../../../../utils/UtilsKeyboardAccessibility";

interface Props {
  open: boolean;
  onClickClose: () => void;
  headerTestid?: string;
}

export const UsageGuide: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isMobile, windowSize } = useWindowSize();
  const { open, onClickClose, headerTestid } = props;
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  useEffect(() => {
    UtilsKeyboardAccessibility.addTabEventListener(
      "close-usage-guide-modal",
      "usage-guide-container"
    );

    return () =>
      UtilsKeyboardAccessibility.removeTabEventListener(
        "close-usage-guide-modal",
        "usage-guide-container"
      );
  }, []);

  useEffect(() => {
    if (open) {
      UtilsKeyboardAccessibility.setFocus("usage-guide-container");
    }
  }, [open]);

  useEffect(() => {
    if (isMobile) {
      document.getElementById(
        "usage-guide-modal"
      )!.style.height = `${window.innerHeight}px`;
    } else {
      document.getElementById("usage-guide-modal")!.style.height = "";
    }
  }, [isMobile, windowSize]);

  return (
    <div className="usage-guide-modal" id="usage-guide-container" tabIndex={0}>
      <Modal show={open} modalBodyId="usage-guide-modal">
        <Modal.Header onHide={onClickClose} data-testid={headerTestid}>
          <Modal.Title>{t("PRIVACY_MYDATA_USAGE_GUIDE_TITLE")}</Modal.Title>
        </Modal.Header>
        <Modal.Body id="usage-guide-body">
          <div className="usage-guide-modal__message">
            <Typography variant="body2" weight="w400" component="p">
              {t("PRIVACY_MYDATA_USAGE_GUIDE_DESC")}
            </Typography>
          </div>
          {pathname !== ROUTES.ROOT && (
            <>
              <Typography
                variant="body1"
                weight="w700"
                className="usage-guide-modal__heading"
              >
                {t("PRIVACY_MYDATA_USAGE_GUIDE_GUIDE1_TITLE")}
              </Typography>
              <Typography
                variant="body2"
                weight="w400"
                className="usage-guide-modal__paragraph"
                component="p"
              >
                {t("PRIVACY_MYDATA_USAGE_GUIDE_GUIDE1_TEXT")}
              </Typography>
            </>
          )}
          <Typography
            variant="body1"
            weight="w700"
            className="usage-guide-modal__heading"
          >
            {t("PRIVACY_MYDATA_USAGE_GUIDE_GUIDE2_TITLE")}
          </Typography>
          <Typography
            variant="body2"
            weight="w400"
            className="usage-guide-modal__paragraph"
            component="p"
          >
            {pathname !== ROUTES.ROOT
              ? t("PRIVACY_MYDATA_USAGE_GUIDE_GUIDE2_TEXT")
              : t("PRIVACY_MYDATA_USAGE_GUIDE_GUIDE2_TEXT_HOME")}
          </Typography>

          {isMobile ? (
            <div
              dir="ltr"
              className={layoutState.isRTL ? "usage-guide-modal__rtl" : ""}
            >
              <Carousel
                defaultControlsConfig={{
                  pagingDotsContainerClassName: "paging-dots",
                }}
                slideIndex={layoutState.isRTL ? 2 : 0}
              >
                {layoutState.isRTL ? <OptOutGuide /> : <DownloadGuide />}
                <DeleteGuide />
                {layoutState.isRTL ? <DownloadGuide /> : <OptOutGuide />}
              </Carousel>
            </div>
          ) : (
            <>
              <DownloadGuide />
              <DeleteGuide />
              <OptOutGuide />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="button-container">
            <Button
              id="close-usage-guide-modal"
              type="primary"
              title={t("PRIVACY_MYDATA_USAGE_GUIDE_BTN")}
              onClick={onClickClose}
            ></Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
